<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof option.id === 'string' || option.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.name" label="Template Name" placeholder="Nama Template" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-select v-model="form.code" :items="codes" label="Template Code" outlined hide-details required :rules="[v => !!v]"
                        :return-object="false"
                        />
                  </v-col>
                  <v-col v-if="$store.state.user.code === 'SPA'" cols="12" md="4">
                     <v-select v-model="form.zone" :items="[{ name: 'All Management', zone: '*', disabled: true }, ...zones]" label="Management Zone" placeholder="Zona Manajemen" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="zone"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col v-for="key in Object.keys(role)" :key="key" cols="12" md="3">
                     <v-select v-model="role[key]" :items="generateCRUD(key)" :label="`${key[0].toUpperCase() + key.slice(1)} Role`" outlined hide-details multiple
                        :return-object="false"
                        >
                        <template v-slot:selection="{ item, index }">
                           <span v-if="index < 2">{{ index === 0 ? item.text : `, ${item.text}` }}</span>
                           <span v-if="index === 2" class="grey--text ml-2">
                              ( +{{ role[key].length - 2 }} more )
                           </span>
                        </template>
                     </v-select>
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                        {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { } from '@/utils/nirtara'
import { } from '@/utils/fields.js'
const crud = {
   member: 'CRUDEIP',
   receptionist: 'CRUD',
   building: 'CRUDIE',
   floor: 'CRUDIE',
   room: 'CRUDIE',
   organization: 'CRUDIE',
   approval: 'CRUDEP',
   invitation: 'CRUD',
   presence: 'REGP',
   setting: 'R',
   management: 'CRUD',
   roles: 'CRUD',
   global: 'RU',
   database: 'RIED',
   notification: 'RU',
   integration: 'CRUD',
   sequence: 'CRUD',
   dashboard: 'R',
   report: 'R',
}
const empty = { kind: 'Roles', zone: '', identity: '', name: '', code: '' }
export default {
   name: 'NRoleForm',

   props: {
      option: { type: Object },
      zones: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
      role: Object.assign({}, ...Object.keys(crud).map(r => { return { [r]: [] } })),
      codes: [
         { value: 'SPA', text: 'SPA — Super Administrator', disabled: true },
         { value: 'STA', text: 'STA — Site Administrator' },
         { value: 'STO', text: 'STO — Site Operator' },
         { value: 'TRO', text: 'TRO — Tenant Relation' },
         { value: 'RCP', text: 'RCP — Guest Reception' },
      ],
   }),

   computed: {
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         const superOnly = this.$store.state.user.code === 'SPA' ? [] : ['management']
         this.role = Object.assign({}, ...Object.keys(crud).filter(s => !superOnly.includes(s)).map(r => { return { [r]: [] } }))
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
         if (id === 0) {
            // Multiple SPA
            /*
            if (this.$store.state.user.code === 'SPA') {
               this.form.code = 'SPA'
               this.form.zone = '*'
            }
            */
         } else {
            const roles = Object.keys(crud)
            this.option.Roles.map(r => Object.keys(r).filter(key => roles.includes(key)).map(key => { this.role[key] = r[key].split('') }))
         }
      },
   },
   mounted () {
   },
   created () {
      this.$store.state.user.code !== 'SPA' && this.codes.splice(this.codes.findIndex(j => j.value === 'SPA'), 1)
   },
   methods: {
      generateCRUD (key) {
         return [
            { value: 'C', text: 'Create' },
            { value: 'R', text: 'Read' },
            { value: 'U', text: 'Update' },
            { value: 'D', text: 'Delete' },
            { value: 'I', text: 'Import' },
            { value: 'E', text: 'Export' },
            { value: 'G', text: 'Graphic' },
            { value: 'P', text: 'Print' },
         ].filter(j => crud[key].split('').includes(j.value))
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         const { form, role } = this
         const roles = { Roles: Object.keys(role).filter(key => role[key].length).map(key => { return { Role: [], [`${key}`]: role[key].join('') } }) }
         this.$emit('update', Object.assign(form, roles))
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
